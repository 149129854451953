import localConfig from '../config/config.json';

const getConfigURL = function (): string {
  const configURLs = {
    test: 'https://byggesoknaden-frontend-config-test.s3.eu-north-1.amazonaws.com/test-config.json?cache-buster=12345',
    demo: 'https://byggesoknaden-frontend-config-test.s3.eu-north-1.amazonaws.com/demo-config.json?cache-buster=12345',
    staging:
      'https://byggesoknaden-frontend-config-test.s3.eu-north-1.amazonaws.com/staging-config.json?cache-buster=12345',
    prod: 'https://byggesoknaden-frontend-config-prod.s3.eu-north-1.amazonaws.com/prod-config.json?cache-buster=12345'
  };

  let CONFIG_URL = configURLs.test;

  switch (true) {
    case window.location.hostname.includes('test.planprosessen.no'):
    case window.location.hostname.includes('test.byggesoknaden.no'): {
      CONFIG_URL = configURLs.test;
      break;
    }

    case window.location.hostname.includes('demo.planprosessen.no'):
    case window.location.hostname.includes('demo.byggesoknaden.no'): {
      CONFIG_URL = configURLs.demo;
      break;
    }

    case window.location.hostname.includes('staging.planprosessen.no'):
    case window.location.hostname.includes('staging.byggesoknaden.no'): {
      CONFIG_URL = configURLs.staging;
      break;
    }

    case window.location.hostname.includes('www.planprosessen.no'):
    case window.location.hostname.includes('www.byggesoknaden.no'): {
      CONFIG_URL = configURLs.prod;
      break;
    }

    default: {
      CONFIG_URL = configURLs.test;
      break;
    }
  }

  return CONFIG_URL;
};

namespace ConfigDataService {
  const CONFIG_URL = getConfigURL();

  export enum Environment {
    beta = 'beta',
    prod = 'prod'
  }

  export interface ApplicationProducts {
    ACTIVE_LAND_REGISTRY_REPORT_EASEMENTS: string;
    ACTIVE_LAND_REGISTRY_REPORT_LANDDATA: string;
    ACTIVE_LAND_REGISTRY_REPORT_PRIVILEGES: string;
    ARBEIDSTILSYNET: string;
    BUILDING_APPLICATION: string;
    DOK_ANALYSIS: string;
    ETTRINN_PRODUCT: string;
    FA_PRODUCT: string;
    IG_PRODUCT: string;
    MBT_PRODUCT: string;
    NEW_PROJECT: string;
    NEIGHBOR_DISTRIBUTION: string;
    NEIGHBOR_DISTRIBUTION_MAKS: string;
    PLANVARSEL: string;
    SOKNAD_UTEN_ANSVARSRETT_PRODUCT: string;
    SUPPLERING_AV_SOKNAD: string;
    TILTAKSHAVERS_SAMTYKKE: string;
    MANGELBESVARELSE: string;
  }

  export interface FeatureFlags {
    enableTiltakshaversSamtykke: boolean;
  }

  export interface Config {
    adminOrgs: string[];
    projectPriceOrgs: string[];
    tiltakshaversSamtykkeOrgs: string[];
    altinn: {
      authority: string;
      client_id: string;
      paths: {
        admbygg: string;
        redirectAfterLogin: string;
        backendApi: string;
      };
      scopesToRequest: string;
      apiKey: string;
    };
    maks: {
      paths: string[];
      apiKey: string;
      domain: string;
    };
    altinnPlanprosessen: {
      client_id: string;
      paths: {
        redirectAfterLogin: string;
      };
    };
    dibk: {
      paths: {
        enterpriseCentralApprovalCheck: string;
        dibkValidate: string;
        ansakoAPI: string;
        arbeidstilsynetChecklist: string;
        planoppstart: string;
        altinn3env: string;
        altinn2env: string;
      };
    };
    environment: string;
    featureToggles: FeatureFlags;
    geoinnsyn: {
      paths: {
        mapIframe: string;
      };
    };
    logging: {
      endpoint: string;
      component: string;
    };
    internalPublicPath?: string;
    products: ApplicationProducts;
    ambitaSignup: string;
  }

  let config: Config;

  const setLocalConfig = function (): Config {
    config = localConfig;

    return localConfig;
  };

  export async function fetchConfig(): Promise<Config> {
    try {
      if (!!import.meta.env.VITE_IS_LOCAL_DEV) {
        return setLocalConfig();
      }

      config = await fetch(CONFIG_URL, {
        mode: 'cors'
      }).then((response) => response.json());

      return config;
    } catch (error) {
      console.error(error?.message, error);

      return Promise.reject(Error('Could not read config'));
    }
  }

  export function getConfig(): Config {
    if (!config) {
      if (!!import.meta.env.VITE_IS_LOCAL_DEV) {
        return setLocalConfig();
      }

      throw Error('No config loaded');
    }

    return config;
  }

  export const isTestAndNotUnitTesting = function (): boolean {
    return isTestEnvironment() && !import.meta.env.VITE_IS_UNIT_TESTING;
  };

  export const isOfficialEnvironment = function (): boolean {
    const officialDomainBS = 'byggesoknaden.no';
    const officialDomainPP = 'planprosessen.no';

    return location.hostname.includes(officialDomainBS) || location.hostname.includes(officialDomainPP);
  };

  export const isTestEnvironment = function (): boolean {
    try {
      const currentConfig: Config = getConfig();
      const { environment } = currentConfig;

      return environment !== Environment.prod;
    } catch {
      return false;
    }
  };

  export const isProdEnvironment = function (): boolean {
    try {
      const currentConfig: Config = getConfig();
      const { environment } = currentConfig;

      return environment === Environment.prod;
    } catch {
      return false;
    }
  };
}

export default ConfigDataService;
